'use client';
import Link from 'next/link';
import clsx from 'clsx';
import { useFormStatus } from 'react-dom';
import { useEffect, useRef, useState } from 'react';

const baseStyles = {
  solid:
    'inline-flex justify-center rounded-lg py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors disabled:opacity-50 disabled:cursor-not-allowed',
  outline:
    'inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors disabled:opacity-50 disabled:cursor-not-allowed',
  neobrutalist:
    'inline-flex justify-center rounded-lg py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors disabled:opacity-50 disabled:cursor-not-allowed',
};

const variantStyles = {
  solid: {
    green:
      'relative overflow-hidden bg-green-700 text-white before:absolute before:inset-0 active:before:bg-transparent hover:before:bg-white/10 active:bg-green-800 active:text-white/80 before:transition-colors disabled:opacity-50 disabled:cursor-not-allowed',
    white:
      'bg-white text-green-900 hover:bg-white/90 active:bg-white/90 active:text-green-900/70 disabled:opacity-50 disabled:cursor-not-allowed',
    gray: 'bg-gray-800 text-white hover:bg-gray-900 active:bg-gray-800 active:text-white/80 disabled:opacity-50 disabled:cursor-not-allowed',
  },
  outline: {
    gray: 'border-gray-300 text-gray-700 hover:border-gray-400 active:bg-gray-100 active:text-gray-700/80 disabled:opacity-50 disabled:cursor-not-allowed',
  },
  neobrutalist: {
    green:
      'px-5 py-2 font-medium text-green-900 border border-b-4 border-r-4 border-green-600 rounded-lg shadow-lg hover:shadow-sm disabled:opacity-50 disabled:cursor-not-allowed',
  },
};

type ButtonProps = (
  | {
      variant?: 'solid';
      color?: keyof typeof variantStyles.solid;
    }
  | {
      variant: 'outline';
      color?: keyof typeof variantStyles.outline;
    }
  | {
      variant: 'neobrutalist';
      color?: keyof typeof variantStyles.neobrutalist;
    }
) &
  (
    | Omit<React.ComponentPropsWithoutRef<typeof Link>, 'color'>
    | (Omit<React.ComponentPropsWithoutRef<'button'>, 'color'> & {
        href?: undefined;
      })
  );

export function Button({ className, ...props }: ButtonProps) {
  props.variant ??= 'solid';
  props.color ??= 'gray';

  className = clsx(
    baseStyles[props.variant],
    props.variant === 'outline'
      ? variantStyles.outline[props.color]
      : props.variant === 'solid'
        ? variantStyles.solid[props.color]
        : props.variant === 'neobrutalist'
          ? variantStyles.neobrutalist[props.color ?? 'green']
          : undefined,

    className,
  );

  const { pending } = useFormStatus();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  return typeof props.href === 'undefined' ? (
    <button ref={buttonRef} disabled={pending} className={className} {...props}>
      {props.children}
    </button>
  ) : (
    <Link className={className} {...props}>
      {' '}
      {props.children}
    </Link>
  );
}
